<svg
	class={$$props.class}
	style={$$props.style}
	fill="none"
	stroke="currentColor"
	viewBox="0 0 24 24"
	version="1.1"
	id="svg4"
	sodipodi:docname="chat-icon.svg"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:svg="http://www.w3.org/2000/svg"
>
	<path
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-width="2"
		d="m 17,8 h 2 a 2,2 0 0 1 2,2 v 6 a 2,2 0 0 1 -2,2 h -2 v 4 L 13,18 H 9 A 1.994,1.994 0 0 1 7.586,17.414 m 0,0 L 11,14 h 4 a 2,2 0 0 0 2,-2 V 6 A 2,2 0 0 0 15,4 H 5 A 2,2 0 0 0 3,6 v 6 a 2,2 0 0 0 2,2 h 2 v 4 z"
		id="path2"
		style="stroke-width:1.8;stroke-dasharray:none"
	/>
	<text
		xml:space="preserve"
		class="text-indigo-600"
		style="font-style:normal;font-variant:normal;font-weight:900;font-stretch:normal;font-size:5.33333px;font-family:'Galano Grotesque Alt';-inkscape-font-specification:'Galano Grotesque Alt, Heavy';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;fill:currentColor;fill-opacity:1;stroke-width:0.5;stroke-dasharray:0, 5.5;stroke-dashoffset:0"
		x="6.8135595"
		y="10.818671"
		id="text168"><tspan sodipodi:role="line" id="tspan166" x="6.8135595" y="10.818671">+1</tspan></text
	>
</svg>
